var cache = {
    brands: [],
    brandMatches: [],
    cats: [],
    items: [],
    itemMatches: []
},
    brandsTemplate = {
        filterMain: []
        // Precompilation will be tough with translations.
        //items: hbs('items')
    };
//shopsTemplate = {};
//itemsTemplate = {};

// helpers
function hbsc(str) {
    return Handlebars.compile(str);
}

function hbs(template) {
    var $template = $('#template-' + template);
    return $template.length ? hbsc($template.html()) : function () { };
}

Handlebars.registerHelper("inc", function (value, options) {
    return parseInt(value) + 1; //increment int by 1
});
Handlebars.registerHelper("debug", function (optionalValue) {
    console.log("Current Context");
    console.log("====================");
    console.log(this);

    if (optionalValue) {
        console.log("Value");
        console.log("====================");
        console.log(optionalValue);
    }
});

function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
};

function getSearchData() {
    $.getJSON('/api/brands', function (brands) {
        cache.brands = brands;
    });

    // $.getJSON('/api/cats', function(cats) {
    //     cache.cats = cats;
    // });

    // $.getJSON('/api/items', function(items) {
    //     cache.items = items;
    // });
}

$(document).ready(function () {
    var t0 = performance.now();
    getSearchData(); // Takes ~5ms to fire
    var t1 = performance.now();
    //console.log("Call to getSearchData took " + (t1 - t0) + " milliseconds.");

    $('ul.nav li.dropdown').hover(function () {
        if (!$('.navbar-toggle').is(':visible')) {
            $(this).toggleClass('open', true);
            $('#backdrop').toggleClass('open', true);
        }
    }, function () {
        if (!$('.navbar-toggle').is(':visible') && !$('#searchresults').hasClass('open')) {
            $(this).toggleClass('open', false);
            $('#backdrop').toggleClass('open', false);
        }
    });
    $('ul.nav li.dropdown a').click(function () {
        if (!$('.navbar-toggle').is(':visible') && $(this).attr('href') !== '#') {
            $(this).toggleClass('open', false);
            window.location = $(this).attr('href');
        }
    });

    // Bind the tab key to traverse through the search results, needs tabindex from 1 and up on class="searchresults-searched-string".
    /*$('#searchbar input[type="text"]').bind('keydown', function (e) {
        if (e.keyCode == 9) { // Tab
            e.preventDefault();
            // the class "searchresults-searched-string" must be in the beginning of classList for the search to work
            var tabindex = $('*[class^="searchresults-searched-string"]').attr('tabindex');
            $('[tabindex="' + tabindex + '"]').focus();
        } else if (e.keyCode == 40) { // Arrow down
            e.preventDefault();
            var tabindex = $('*[class^="searchresults-searched-string"]').attr('tabindex');
            $('[tabindex="' + tabindex + '"]').focus();
        } else if (e.keyCode == 38) { // Arrow up
            e.preventDefault();
            var tabindex = $('*[class^="searchresults-searched-string"]').attr('tabindex');
            if (tabindex > 1) {
                tabindex--;
            }
            $('[tabindex="' + tabindex + '"]').focus();
        } else if (e.keyCode == 27) { // ESC
            showSearchresults(false);
        }
    });*/

    /*function showSearchresults(bool = true) {
        $('#searchbar').toggleClass('search-open', bool);
        $('body').toggleClass('offcanvas-stop-scrolling', bool);
        $('#searchresults').toggleClass('open', bool);
        $('#searchresults .dropdown').toggleClass('open', bool);
        $('#backdrop').toggleClass('open search-opened', bool);
        return true;
    }*/

    // Search results dropdown
    /*$('#searchbar input[type="text"]').on('input', function (e) {
        var numBrandsToMatch = 15;
        var brandsTemplate = hbs('searchresults-brands');
        //var shopsTemplate = hbs('searchresults-shops');
        //var itemsTemplate = hbs('searchitems-items');
        var currSearchString = escapeRegExp(e.target.value);
        cache.brandMatches = [];

        var searchedString = new RegExp('(' + currSearchString + ')', 'ig');
        var foundString = false;

        if (currSearchString.length > 0) {
            console.log(currSearchString);
            for (var j = 0; j < cache.brands.length && cache.brandMatches.length <= numBrandsToMatch; j++) {
                templatedString = cache.brands[j];
                foundString = searchedString.test(templatedString.name);
                if (foundString) {
                    templatedString['type'] = 'brand';
                    cache.brandMatches.push(templatedString);
                }
            }
            // foundString = false;
            // for(var k = 0; k < cache.items.items.length && cache.itemMatches.length < 15; k++){
            //     templatedString = cache.items.items[k];
            //     foundString = searchedString.test(templatedString.name);
            //     if (foundString){
            //         templatedString['type'] = 'item';
            //         cache.matches.push(templatedString);
            //     }
            // }

            $("#searchresults-brands").html(brandsTemplate(cache.brandMatches));
            //$("#searchresults-shops").html(shopsTemplate(cache.brandMatches));
            //$("#searchresults-items").html(itemsTemplate(cache.brandMatches));

            // RegEx match exact word (insensitive case) in searchresults and return same string with bolded match
            // the class "searchresults-searched-string" must be in the beginning of classList for the detection to work
            var arrStringsToMatch = $('*[class^="searchresults-searched-string"]');
            for (var i = 0; i < arrStringsToMatch.length; i++) {
                $(arrStringsToMatch[i]).html(
                    $(arrStringsToMatch[i]).text().replace(searchedString, '<b>$1</b>')
                );
            };

            showSearchresults(true);
        } else {
            showSearchresults(false);
        }

        // Detect if soft keyboard is used
        // $(this).bind('focus',function() {
        //     $(window).scrollTop(10);
        //     var keyboard_shown = $(window).scrollTop() > 0;
        //     $(window).scrollTop(0);

        //     $('#searchresults').append(keyboard_shown?' keyboard ':' nokeyboard ');
        // });
    }).on('blur', function (e) { //TODO: Find a better way to register "onBlur" so that the searchmenu is more usable.
        showSearchresults(false);
    });*/

    if ($(window).width() < 768) {

        var content;
        var el;

        $('#close-menu').click(function () {

            if ($('.mega-dropdown.active').length > 0) {
                $('.mega-dropdown').show();
                $('.mega-dropdown.active').children('a').html(content);
                $('.mega-dropdown.active').removeClass('active');
            }
            $('.navbar-toggle').removeClass('is-open');
            $('.navbar-offcanvas').toggleClass('in');
            $('#backdrop').toggleClass('in');
            $('.navlink').removeClass('hidden-nav');
            $('.city-btn').removeClass('hidden-nav');
            $('body').removeClass('offcanvas-stop-scrolling');
        });

        $('.dropdown-toggle').click(function () {

            if ($(this).parent('.mega-dropdown').hasClass('active')) {
                el = $(this);
                content = $(this).html();
                $(this).html('<object class="mobshow back-svg" type="image/svg+xml" data="app/images/icons/forward-blue.svg" class="logo"><!-- fallback --></object> Tillbaka');
                $('.mega-dropdown').hide();
                $('.mega-dropdown.active').show();
                $('.mega-dropdown.active').css({ 'display': 'block', 'margin-left': '-100px', 'opacity': 0 });
                $('.mega-dropdown.active').animate({ opacity: '1', marginLeft: '0px' }, 200);
                $('.navlink').toggleClass('hidden-nav');
                $('.city-btn').toggleClass('hidden-nav');
            } else {
                $('.mega-dropdown').show();
                $('.navlink').toggleClass('hidden-nav');
                $('.city-btn').toggleClass('hidden-nav');
                $(this).html(content);
            }

        });
    }

    jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });


    /**
     * This object controls the nav bar. Implement the add and remove
     * action over the elements of the nav bar that we want to change.
     *
     * @type {{flagAdd: boolean, elements: string[], add: Function, remove: Function}}
     */
    var myNavBar = {

        flagAdd: true,

        elements: [],

        init: function (elements) {
            this.elements = elements;
        },

        add: function () {
            if (this.flagAdd) {
                for (var i = 0; i < this.elements.length; i++) {
                    document.getElementById(this.elements[i]).className += " fixed-theme";
                }
                this.flagAdd = false;
            }
        },

        remove: function () {
            for (var i = 0; i < this.elements.length; i++) {
                document.getElementById(this.elements[i]).className =
                    document.getElementById(this.elements[i]).className.replace(/(?:^|\s)fixed-theme(?!\S)/g, '');
            }
            this.flagAdd = true;
        }

    };

    /**
     * Init the object. Pass the object the array of elements
     * that we want to change when the scroll goes down
     */
    myNavBar.init([
        "header-container",
        //"header-searchresults",
        "header-item",
        "header-wishing",
        "header-profile",
        "brand",
        "topbar",
        "searchbar",
        "backdrop",
        "main-header",
        "js-bootstrap-offcanvas"
    ]);

    /**
     * Function that manage the direction
     * of the scroll
     */
    function offSetManager() {

        var yOffset = 0;
        var currYOffSet = window.pageYOffset;

        if (yOffset < currYOffSet) {
            myNavBar.add();
        }
        else if (currYOffSet == yOffset) {
            myNavBar.remove();
        }

    }

    /**
     * bind to the document scroll detection
     */
    window.onscroll = function (e) {
        offSetManager();
    };

    /**
     * We have to do a first detectation of offset because the page
     * could be load with scroll down set.
     */
    offSetManager();

});

/* Instagram */

function JSONPRequest(url) {
    var s = document.createElement('script');
    s.setAttribute('src', url);
    document.getElementsByTagName('head')[0].appendChild(s);
}

function sendRequest() {
    var imgLimit = 5; // Antal bilder du vill visa (upp till 20 st)
    var accessToken = '4193022511.1f78867.7a197b5aab604213bcb7d8d363d3fd31'; // Accesstoken från client
    var userID = '4193022511'; // AnvändarID
    JSONPRequest('https://api.instagram.com/v1/users/' + userID + '/media/recent?access_token=' + accessToken + '&callback=callbackFunction&count=' + imgLimit);
}

function callbackFunction(response) {
    var imgsrc;
    var caption;
    var user;
    var filter;
    var link;
    var imgdiv = document.getElementById('imgdiv');

    for (var i = 0; i < response.data.length; i++) {
        imgsrc = response.data[i].images.standard_resolution.url;       // Stor bild - bredd 640px 
        lowresimgsrc = response.data[i].images.low_resolution.url;      // Liten bild - Bredd 320px 
        caption = response.data[i].caption.text;                        // Bildtext
        user = response.data[i].user.username;                          // Användarnamn
        filter = response.data[i].filter;                               // Vilket filter som används
        link = response.data[i].link;                                   // Länken till bilden
        if (imgdiv != null) {
            imgdiv.innerHTML = imgdiv.innerHTML +
                '<a target="_blank" href="' + link + '"><div class="instagram-item instagram-picture-' + i + '"><div class="instagram-item-inner"><img class="instagram-image" src="' + lowresimgsrc + '" alt=""></div></div></a>';
        }
    }

    instaWidth();
    $('.instagram-image').hide();
    $('.instagram-image').fadeIn(300);
}

function instaWidth() {
    setTimeout(function () {
        $('.instagram-item-inner').each(function () {
            var width = $(this).width();
            $(this).height(width);
        });
    }, 50);
}



$(window).resize(function () {
    instaWidth();
});



/* Instagram END */



$(document).ready(function () {

    $("input[name$='account']").click(function () {
        var selection = $(this).val();

        $("div.desc").hide();
        $("#account-page-" + selection).show();
    });

    $("input[name$='account-modal']").click(function () {
        var selection = $(this).val();

        $("div.desc").hide();
        $("#account-modal-" + selection).show();
    });

    $("input[name$='winning-modal']").click(function () {
        var selection = $(this).val();

        $("div.desc").hide();
        $("#winning-modal-" + selection).show();
    });

    $("#hide-offer").click(function () {
        $("#o-offer-banner").css("display", "none");
    });

    /* calculate last item in list row */
    /* NOT USED ANYMORE, Waiting with removing /Sven */

    /*
        function calculateLIsInRow() {
            var lisInRow = 0;
            $('.o-shoe-sizes li').each(function() {
                $(this).removeClass("last-size");
                if($(this).prev().length > 0) {
                    if($(this).position().top != $(this).prev().position().top) 
                        {
                            $(this).addClass("last-size");
                            //return false;
                        }
                    
                    lisInRow++;
                }
                
                else {
                   
                    lisInRow++;   
                }
                if($(this).next().length > 0) {
                           }
                else{ $(this).addClass("last-size");
        }
            });
            
            $('.result').html('No: of lis in a row = ' + lisInRow);
        }
    
        calculateLIsInRow();
    
        $(window).resize(calculateLIsInRow);
    */
    /* selects show sizes */

    $('.o-shoe-sizes li').on('click', function () {
        if ($(this).hasClass('added-size')) {
            $(this).removeClass('added-size');
        } else { $(this).addClass('added-size'); }
    });

});


